<template>
  <div id="app">
    <div v-if="loading">
      <div id="load">
        <div>S</div>
        <div>E</div>
        <div>X</div>
        <div>O</div>
        <div>B</div>
        <div>T</div>
        <div>O</div>
        <div>O</div>
        <div>L</div>
      </div>
    </div>
    
    <div v-else>
      <RouterView />
      <NavigationPanel />
    </div>
  
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import NavigationPanel from './components/NavigationPanel.vue';


const responseData = ref(null)
const loading = ref(true)
const error = ref(null)

// Получаем CSRF-токен
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

// Устанавливаем CSRF-токен в заголовки по умолчанию
axios.defaults.headers.common['X-CSRFToken'] = csrfToken

onMounted(() => {
  window.Telegram.WebApp.ready();
  timer();
  const initData = window.Telegram.WebApp.initData;
  console.log(initData);
  console.log(initData.query_id);
  console.log(initData.user);

  // Отправляем данные на серверную часть
  axios.post('/core/api/data/', {
    initData
  })
  .then(response => {
    responseData.value = response.data.message;
    setTimeout(() => {
      loading.value = false;
    }, 2200);
  })
  .catch(err => {
    error.value = 'Error occurred while sending data.';
    loading.value = false;
    console.log(err);
  });
});

function timer() {
  const loaderElements = document.querySelectorAll('#load div');
  
  loaderElements.forEach((element) => {
    element.addEventListener('animationstart', () => {
      if (loading.value) {
        triggerHapticFeedback();
      }
    });
  });

}

const triggerHapticFeedback = () => {
  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
  } else {
    console.warn("Haptic feedback is not supported or WebApp.HapticFeedback is not available.");
  }
};

</script>

<style scoped>
#load {
  position:absolute;
  width:600px;
  height:36px;
  left:50%;
  top:40%;
  margin-left:-300px;
  overflow:visible;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  cursor:default;
}

#load div {
  animation-iteration-count: 1;
  animation: move 2s linear;
  position: absolute;
  width: 20px;
  height: 36px;
  font-family: Helvetica, Arial, sans-serif;
  color: #35C4F0;
}

#load div:nth-child(2) { animation-delay: 0.2s; }
#load div:nth-child(3) { animation-delay: 0.4s; }
#load div:nth-child(4) { animation-delay: 0.6s; }
#load div:nth-child(5) { animation-delay: 0.8s; }
#load div:nth-child(6) { animation-delay: 1s; }
#load div:nth-child(7) { animation-delay: 1.2s; }
#load div:nth-child(8) { animation-delay: 1.4s; }
#load div:nth-child(9) { animation-delay: 1.6s; }

@keyframes move {
  0% { left: 0; opacity: 0; transform: rotate(180deg); }
  35% { left: 41%; transform: rotate(0deg); opacity: 1; }
  65% { left: 59%; transform: rotate(0deg); opacity: 1; }
  100% { left: 100%; transform: rotate(-180deg); opacity: 0; }
}


</style>
