<template>
    <div v-if="winRecords.length > 0">
      <div v-for="(record, index) in winRecords" :key="index" class="coin-record">
        <img :src="getCoinImage(record.crypto_name)" alt="Coin" class="coin-image" />
        <div class="coin-details">
          <span class="coin-name">{{ record.crypto_name }}</span>
          <span class="coin-count">{{ record.count }} Tokens</span>
        </div>
      </div>
    </div>
    <div v-else>
      <p>No win records found.</p>
    </div>
  </template>
  
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRFToken'] = csrfToken;

const winRecords = ref([]); // Храним записи о выигрыше пользователя

const initData = ref(null); // Данные инициализации WebApp
const telegramId = ref(null);

// Функция для получения изображений криптовалют
const getCoinImage = (cryptoName) => {
  return `@/assets/coins/${cryptoName.toLowerCase()}.png`; // Предполагается, что изображения хранятся по названию монеты
};

// Получаем данные о выигрышах пользователя
const getWinRecords = async () => {
  if (telegramId.value && initData.value) {
    try {
      const response = await axios.post('/core/api/get-win/', {
        user_id: telegramId.value,
      });

      if (response.data.win_records) {
        winRecords.value = response.data.win_records;
      } else {
        winRecords.value = [];
      }
    } catch (error) {
      console.error('Error fetching win records:', error);
    }
  }
};

onMounted(() => {
  window.Telegram.WebApp.ready();
  initData.value = window.Telegram.WebApp.initDataUnsafe;
  telegramId.value = initData.value.user.id; // Получаем telegram_id из initData

  getWinRecords(); // Запрашиваем данные о выигрышах пользователя
});
</script>
  
  <style scoped>
  .coin-record {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .coin-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .coin-details {
    display: flex;
    flex-direction: column;
  }
  
  .coin-name {
    font-weight: bold;
  }
  
  .coin-count {
    color: #888;
  }
  </style>
  